<template>
  <div>
    <TopMenuSubheadline :title="title"/>
    <div class="app-content-box bg-gray">
      <div class="wrapper pt-4 mb-3">
      <div class="mt-2">
      <div class="form-group form-group-border">
        <div class="container form-container">
          <div class="form-checkbox-switch">
            <input
              type="checkbox"
              name="checkbox-switch_1"
              id="checkbox-switch_1"
              :checked="switch_1"
              @click="switch_1 = !switch_1"
            />
            <label for="checkbox-switch_1">
             <div class="top-heading-medium">Grywalizacja</div>
              <div>Wyłączając grywalizację utracisz możliwość śledzenia swoich postępów</div>
              <span class="switch-pill">
                <span class="switch-dot"></span>
              </span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-0">
    <div class="form-group form-group-border">
      <div class="container form-container">
        <div class="form-checkbox-switch">
          <input
            type="checkbox"
            name="checkbox-switch_2"
            id="checkbox-switch_2"
            :checked="switch_2"
            @click="switch_2 = !switch_2"
          />
          <label for="checkbox-switch_2">
            <div class="top-heading-medium">Publiczna</div>
            <div>Status twojej grywalizacji jst widoczny dla użytkowników odwiedzających twój profil</div>
            <span class="switch-pill">
              <span class="switch-dot"></span>
            </span>
          </label>
        </div>
      </div>
    </div>
    </div>
    <div class="container submit-wrapper">
      <div class="mt-4 mb-2" v-if="alertInfo.length">
        <div class="text-center"><b>{{alertInfo}}</b></div>
      </div>
      <button @click.prevent="submit" class="btn-black-full w-100 mt-4 mb-2">Zaktualizuj</button>
    </div>
      </div>
    </div>
  </div>
</template>
<script>
import TopMenuSubheadline from '../components/TopMenuSubheadline'
export default {
  name: 'Gamification',
  components: {
    TopMenuSubheadline
  },
  data () {
    return {
      title: 'Grywalizacja',
      alertInfo: '',
      switch_1: false,
      switch_2: false
    }
  },
  created () {
    this.$https('/profile', {
      method: 'GET',
      mode: 'no-cors',
      crossDomain: true
    })
      .then(({ data }) => {
        this.switch_1 = data.rivalry
        this.switch_2 = data.public_rivalry
      })
  },
  methods: {
    submit () {
      this.switch_1 = this.switch_1 ? 1 : 0
      this.switch_2 = this.switch_2 ? 1 : 0
      const getData = `rivalry=${this.switch_1}&public_rivalry=${this.switch_2}`
      this.$https('/profile', {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: getData
      }).then(response => {
        this.alertInfo = 'Dane zostały zaktualizowane'
      })
        .catch(error => {
          console.log('', error)
        })
    }
  }
}
</script>
<style lang="scss" scoped>
  .app-content-box {
    min-height: 100vh;
  }
</style>
